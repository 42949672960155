import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faTimes, faUser, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const systemInstructions = `
You are now an assistant who helps users plan their travel itinerary. 
You should get details about:
- Desired travel destination
- Age group of the traveler(s)
- Preferred adventure scale (low, medium, high)

Instructions to follow:
- Introduce yourself as Maya and ask questions about the details individually, keeping the questions short. Do not ask all questions at once.
- Be more casual and don't ask anything other than the above.
- You can ask 5 questions or less and nothing more.
- Finally suggest an itinerary based on the input given by the user in bullet points.
- Add new lines in the message if needed so that the message looks pleasant.
`;

const ChatBot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { text: 'Hello! How can I assist you today?', sender: 'bot' }
  ]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSendMessage = async () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: 'user' };

      // Prepare messages for the API request
      const apiMessages = [
        { role: 'system', content: systemInstructions }, // Add system instructions here
        ...messages.map((msg) => ({
          role: msg.sender === 'user' ? 'user' : 'assistant',
          content: msg.text
        })),
        { role: 'user', content: newMessage.text } // Include the latest user message
      ];

      // Add the user's message to the messages state
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      setInput('');

      try {
        const response = await axios.post('https://api.openai.com/v1/chat/completions', {
          model: "gpt-3.5-turbo",
          messages: apiMessages,
          max_tokens: 150
        }, {
          headers: {
            'Authorization': `Bearer YOUR_API_KEY`,
            'Content-Type': 'application/json'
          }
        });

        console.log('OpenAI response:', response.data);

        // Extract the bot's response from the API
        const botMessage = response.data.choices[0].message.content.trim();
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: botMessage, sender: 'bot' }
        ]);

      } catch (error) {
        console.error('Error fetching response from OpenAI:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Sorry, I am having trouble fetching a response.', sender: 'bot' }
        ]);
      }
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]); // Dependency array includes messages

  return (
    <div style={styles.container}>
      <div
        style={isOpen ? { ...styles.toggleButton, ...styles.toggleButtonOpen } : styles.toggleButton}
        onClick={toggleChat}
      >
        <FontAwesomeIcon icon={isOpen ? faTimes : faRobot} style={styles.icon} />
      </div>
      {isOpen && (
        <div style={styles.chatWindow}>
          <div style={styles.messagesContainer}>
            {messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  ...styles.messageContainer,
                  justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start'
                }}
              >
                <FontAwesomeIcon
                  icon={msg.sender === 'user' ? faUser : faRobot}
                  style={styles.messageIcon}
                />
                <div
                  style={msg.sender === 'user' ? styles.userMessage : styles.botMessage}
                >
                  {msg.text}
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
          <div style={styles.inputContainer}>
            <input
              style={styles.input}
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              placeholder="Type a message..."
            />
            <button style={styles.sendButton} onClick={handleSendMessage}>
              <FontAwesomeIcon icon={faPaperPlane} style={styles.sendIcon} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const styles = {
  toggleButton: {
    backgroundColor: '#0000FF',
    color: 'white',
    padding: '12px',
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '35px',
    height: '35px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    animation: 'vibrate 0.2s ease infinite',  // Add this line
    animationIterationCount: '3',             // Controls how many times the vibration happens
    animationDelay: '3s',                     // Adds a delay before the vibration starts again
  },
  
  // Add this keyframe animation
  '@keyframes vibrate': {
    '0%': { transform: 'translate(0px, 0px)' },
    '20%': { transform: 'translate(-2px, 2px)' },
    '40%': { transform: 'translate(-2px, -2px)' },
    '60%': { transform: 'translate(2px, 2px)' },
    '80%': { transform: 'translate(2px, -2px)' },
    '100%': { transform: 'translate(0px, 0px)' },
  },
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontFamily: 'Roboto, sans-serif',
    zIndex: 1000,
  },
  toggleButtonOpen: {
    transform: 'scale(1.1)',
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.3)',
  },
  icon: {
    fontSize: '20px',
  },
  chatWindow: {
    width: '320px',
    height: '420px',
    borderRadius: '12px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)',
    marginTop: '10px',
    animation: 'fadeIn 0.3s ease',
  },
  messagesContainer: {
    flex: 1,
    padding: '15px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  messageContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  messageIcon: {
    fontSize: '16px',
    color: '#0000FF',
  },
  userMessage: {
    backgroundColor: '#f2f2f2', // Light grey for user messages
    padding: '12px',
    borderRadius: '20px',
    maxWidth: '75%',
    wordBreak: 'break-word',
    color: '#333333', // Dark grey for text
    border: '1px solid #ddd', // Light border
  },
  botMessage: {
    backgroundColor: '#e6e6e6', // Slightly darker grey for bot messages
    padding: '12px',
    borderRadius: '20px',
    maxWidth: '75%',
    wordBreak: 'break-word',
    color: '#333333', // Dark grey for text
    border: '1px solid #ccc', // Lighter border
  },
  inputContainer: {
    display: 'flex',
    borderTop: '1px solid #ddd',
    padding: '10px',
    backgroundColor: '#f9f9f9',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '20px',
    border: '1px solid #ccc',
    outline: 'none',
    fontSize: '14px',
  },
  sendButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: '0',
    marginLeft: '10px',
  },
  sendIcon: {
    fontSize: '20px',
    color: '#0000FF',
  },
};

export default ChatBot;
